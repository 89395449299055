import React, { useState, useEffect } from 'react';
import { setPageTitle } from '../../utils/PageTitle';
import ProjectList from '../../components/ProjectList';
import api from '../../api';
import FAQs from '../../components/FAQs';
import { Link } from 'react-router-dom';
import { MdOutlineArrowBackIosNew } from 'react-icons/md';

export default function Projects() {
  setPageTitle('Projects');
  const [projects, setProjects] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const getProjects = () => {
      api
        .get('/projects')
        .then((response) => {
          setProjects(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getProjects();
  }, []);

  useEffect(() => {
    setSearchResults(
      projects.filter((project) => {
        if (project.title.toLowerCase().includes(searchParam.toLowerCase())) {
          return true;
        }
        return false;
      })
    );
  }, [searchParam]);

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  return (
    <>
      <div className="container-fluid mb-3">
        <Link className="my-bread-crumb" to={`/departments`}>
          <MdOutlineArrowBackIosNew />
          DEPARTMENTS
        </Link>
        <h2>Explore Our Vast Collection of Projects</h2>
        <p>
          <small className="text-muted">
            Browse from our extensive collection of projects available,
            carefully curated to cater to a wide range of academic disciplines.
            Whether you're pursuing a degree in engineering, business, social
            sciences, or any other field, we have a diverse selection of
            projects to choose from. Explore our large collections and discover
            project topics that align with your interests and academic goals.
            With our vast range of options, you're sure to find the perfect
            project material that will propel you towards success.
          </small>
        </p>
        <input
          type="text"
          className="form-control"
          placeholder="Search Projects"
          value={searchParam}
          onChange={handleSearch}
        />
      </div>
      <div className="mb-5">
        <ProjectList
          projects={searchParam === '' ? projects : searchResults}
          fromASearch={searchParam.length !== 0}
        />
      </div>
      <div className="mb-5">
        <FAQs />
      </div>
    </>
  );
}
