import React from 'react';
import faqs from '../../data/faqs.json';
import './styles.css';
import { RiQuestionLine } from 'react-icons/ri';

export default function FAQs() {
  return (
    <>
      <div className="container-fluid">
        <h3>Frequently Asked Questions</h3>
        <p>
          <small>
            Below, you will find answers to some commonly asked questions to
            help you get the most out of your iProjectEdu journey.
          </small>
        </p>
      </div>
      <div className="container-fluid">
        <div className="accordion accordion-flush mb-3" id="accordion">
          {faqs.map((faq, index) => {
            return (
              <div className="accordion-item" key={index}>
                <h5 className="accordion-header" id={`heading_${index}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse_${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse_${index}`}
                  >
                    <div className="d-flex">
                      <div>
                        <RiQuestionLine />
                      </div>
                      <div
                        style={{
                          fontWeight: '600',
                          textTransform: 'uppercase',
                        }}
                      >
                        {faq.question}
                      </div>
                    </div>
                  </button>
                </h5>
                <div
                  id={`collapse_${index}`}
                  className="accordion-collapse collapse bg-secondary text-white"
                  aria-labelledby={`heading_${index}`}
                  data-bs-parent="#accordion"
                >
                  <div className="accordion-body">
                    <ul>
                      <li>{faq.answer}</li>
                    </ul>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <p>
          <small className="text-muted">
            If you have any additional questions or concerns that are not
            addressed here, please feel free to contact our support team. We are
            here to assist you and ensure that your experience with iProjectEdu
            is exceptional. We value your success and are committed to being
            your trusted partner throughout your academic and professional
            journey.
          </small>
        </p>
      </div>
    </>
  );
}
