import React, { useEffect, useState } from 'react';
import { setPageTitle } from '../../utils/PageTitle';
import api from '../../api';
import { showToast } from '../../components/Toast';
import './styles.css';
import Spinner from '../../components/Spinner';
import global from '../../data/global.json';

export default function HireAWriter() {
  const [user, setUser] = useState(null);
  const [request, setRequest] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [educationalLevels, setEducationalLevels] = useState([]);
  const [selectedEducationLevel, setSelectedEducationLevel] = useState(null);
  const [loading, setLoading] = useState(true);
  const chaptersPlaceholders = [
    'All Chapters',
    'Chapter 1',
    'Chapter 2',
    'Chapter 3',
    'Chapter 4',
    'Chapter 5',
  ];

  setPageTitle('Hire A Writer');

  useEffect(() => {
    const getUser = () => {
      api
        .get('/users/@me')
        .then((response) => {
          setUser(response.data);
        })
        .catch((error) => {});
    };

    const getEducationalLevels = () => {
      api
        .get('/educational_levels')
        .then((response) => {
          setEducationalLevels(response.data);
          setSelectedEducationLevel(response.data[0]);
        })
        .catch((error) => {});
    };
    getUser();
    getEducationalLevels();
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [user]);

  const [chapters, setChapters] = useState([]);
  const updateChapters = (e) => {
    const value = Number(e.target.value);
    let copy = [...chapters];
    if (value === 0 && !copy.includes(value)) {
      setChapters([0, 1, 2, 3, 4, 5]);
      return;
    } else if (value === 0 && copy.includes(value)) {
      setChapters([]);
      return;
    } else if (!copy.includes(value) && copy.length == 4) {
      copy.push(0);
    }

    if (copy.includes(value)) {
      copy = copy.filter((x) => x !== 0);
      copy = copy.filter((x) => x !== value);
    } else {
      copy.push(value);
    }
    setChapters(copy);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    if (choicePlan === null) {
      showToast('Please Select a Plan!');
      setProcessing(false);
      return;
    }
    if (chapters.length === 0) {
      showToast('Chapters cannot be empty!');
      setProcessing(false);
      return;
    }
    const formData = new FormData(e.target);
    formData.set('plan_id', choicePlan);
    formData.set('chapters', JSON.stringify(chapters));

    api
      .post('/requests', formData)
      .then((response) => {
        setProcessing(false);
        showToast('Submission Successful!');
        setRequest(response.data);
      })
      .catch((error) => {
        setProcessing(false);
        showToast('Something went wrong!');
        console.log(error);
      });
  };

  const setPlans = (e) => {
    const level_id = e.target.value;
    setChoicePlan(null);
    for (const level of educationalLevels) {
      if (level.id === level_id) {
        setSelectedEducationLevel(level);
        break;
      }
    }
  };

  const [choicePlan, setChoicePlan] = useState(null);
  const updateChoicePlan = (e) => {
    setChoicePlan(e.target.dataset.planId);
  };

  if (loading) {
    return <Spinner />;
  }

  if (request !== null) {
    return <RequestSuccess request={request} />;
  }

  return (
    <>
      <div className="container-fluid mb-4">
        <h1>Hire A Professional Writer</h1>
        <p>
          To get started, simply fill out the form below, providing us with the
          details of your research project. Once we receive your inquiry, our
          friendly support team will reach out to you promptly to discuss the
          project's scope, timeline, and any other specific requirements you may
          have.
        </p>
      </div>
      <div className="container-fluid mb-5">
        <form onSubmit={handleSubmit}>
          {user === null ? (
            <>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Field cannot be blank"
                  name="firstname"
                  required
                />
                <label htmlFor="floatingInput">Firstname</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Field cannot be blank"
                  name="lastname"
                  required
                />
                <label htmlFor="floatingInput">Lastname</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Field cannot be blank"
                  name="email"
                  required
                />
                <label htmlFor="floatingInput">Email</label>
              </div>
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="floatingInput"
                  placeholder="Field cannot be blank"
                  name="phone"
                  required
                />
                <label htmlFor="floatingInput">Phone</label>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="project_title"
              required
            />
            <label htmlFor="floatingInput">Project Title</label>
          </div>

          <div className="form-floating">
            <select
              className="form-select"
              id="floatingSelect"
              aria-label="Floating label select example"
              name="educational_level_id"
              onChange={setPlans}
            >
              {educationalLevels.map((level, index) => {
                return (
                  <option value={level.id} key={level.id}>
                    {level.name}
                  </option>
                );
              })}
            </select>
            <label htmlFor="floatingSelect">Educational Level</label>
          </div>

          <div className="row mb-4 px-2">
            {selectedEducationLevel !== null ? (
              selectedEducationLevel.plans.map((plan) => {
                return (
                  <div
                    className="card text-start col-md-6 mx-2 my-2 p-0 plan-card"
                    style={{ maxWidth: '320px' }}
                    key={plan.id}
                  >
                    <div
                      className="card-body"
                      onClick={updateChoicePlan}
                      data-plan-id={plan.id}
                    >
                      <div
                        className="d-flex justify-content-between"
                        onClick={updateChoicePlan}
                        data-plan-id={plan.id}
                      >
                        <p
                          className="text-primary"
                          style={{ fontWeight: 'bold', marginBottom: '0' }}
                          onClick={updateChoicePlan}
                          data-plan-id={plan.id}
                        >
                          {plan.name} <br />
                          <span
                            className="text-muted"
                            onClick={updateChoicePlan}
                            data-plan-id={plan.id}
                          >
                            {`${global.currency.ngn}${Number(
                              plan.price
                            ).toLocaleString()}`}
                            <small
                              style={{ fontWeight: 'normal' }}
                              onClick={updateChoicePlan}
                              data-plan-id={plan.id}
                            >
                              /
                              {plan.per_chapter === true
                                ? 'per chapter'
                                : 'complete project'}
                            </small>
                          </span>
                        </p>
                        <input
                          type="radio"
                          onClick={updateChoicePlan}
                          data-plan-id={plan.id}
                          checked={plan.id === choicePlan}
                          readOnly
                        />
                      </div>
                      <small
                        className="text-danger"
                        onClick={updateChoicePlan}
                        data-plan-id={plan.id}
                      >
                        {plan.note}
                      </small>
                      <br />
                      <small
                        className="card-text text-muted"
                        onClick={updateChoicePlan}
                        data-plan-id={plan.id}
                      >
                        {plan.info}
                      </small>
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>

          <div className="p-3">
            <p>Select Chapters: </p>
            {chaptersPlaceholders.map((chapterPlaceholder, index) => {
              return (
                <div className="form-check form-check-inline" key={index}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id={`inlineCheckbox${index}`}
                    value={index}
                    checked={chapters.includes(index)}
                    onClick={updateChapters}
                    readOnly
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`inlineCheckbox${index}`}
                  >
                    {chapterPlaceholder}
                  </label>
                </div>
              );
            })}
          </div>

          <div className="form-floating">
            <textarea
              className="form-control"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="department_format"
            />
            <label htmlFor="floatingInput">Department/Format</label>
          </div>

          <button className="btn btn-lg btn-primary w-100" type="submit">
            {processing ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>{' '}
                Processing...
              </>
            ) : (
              'Submit Request'
            )}
          </button>
        </form>
      </div>
    </>
  );
}

function RequestSuccess({ request }) {
  return (
    <>
      <div className="container-fluid mb-4">
        <h1>Request Received: Proceed to Payment</h1>
        <p>
          Thank you for reaching out to our professional writing services. Your
          request has been successfully submitted. Our dedicated team will
          promptly review the details of your research project. We will get in
          touch with you shortly to discuss the scope, timeline, and any
          specific requirements you may have.
        </p>

        <p>
          You can proceed to make payment after reviewing the summary of your
          request in the following table.
        </p>
        <table>
          <tr>
            <th>Request Project Title</th>
            <td>{request.project_title}</td>
          </tr>
          <tr>
            <th>Educational Level</th>
            <td>{request.educational_level}</td>
          </tr>
          <tr>
            <th>Department/Format</th>
            <td>{request.department_format}</td>
          </tr>
          <tr>
            <th>Chapters</th>
            <td>{request.chapters}</td>
          </tr>
          <tr>
            <th>Plan</th>
            <td>{request.plan}</td>
          </tr>
          <tr>
            <th>Price</th>
            <td>{`${global.currency.ngn}${Number(
              request.price
            ).toLocaleString()}`}</td>
          </tr>
          <tr>
            <th>Customer Email</th>
            <td>{request.user.email}</td>
          </tr>
        </table>
      </div>
      <div className="mb-5">
        <a
          href={request.auth_url}
          className="btn btn-lg btn-secondary ms-2 me-2 mb-3 mb-md-0"
        >
          Proceed to Payment
        </a>
        <a
          href="/hire-a-writer"
          className="btn btn-lg btn-primary ms-2 me-2 mb-3 mb-md-0"
        >
          Submit Another Request
        </a>
      </div>
    </>
  );
}
