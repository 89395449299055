import React from 'react';
import './styles.css';
import ManageDepartments from './ManageDepartments';
import ManageProjects from './ManageProjects';
// import ManageUsers from './ManageUsers';
import { useLocation } from 'react-router-dom';
import { setPageTitle } from '../../utils/PageTitle';
import ManageOrders from './ManageOrders';
import ManageRequests from './ManageRequests';

export default function Manage() {
  let activeTab = useLocation().hash.slice(1);
  const tabs = {
    Departments: <ManageDepartments />,
    Projects: <ManageProjects />,
    Orders: <ManageOrders />,
    Requests: <ManageRequests />,
    // Users: <ManageUsers />,
  };
  if (activeTab === '') {
    activeTab = Object.keys(tabs)[0];
  }
  setPageTitle(`Manage ${activeTab}`);
  return (
    <>
      <div className="container">
        <div className="container-fluid mb-4">
          <h2>Manage Projects & Departments</h2>
          <ul className="nav nav-tabs mt-3">
            {Object.entries(tabs).map(([name, value], index) => {
              let activeStyle = 'nav-link';
              if (name === activeTab) {
                activeStyle = 'nav-link active';
              }
              return (
                <li className="nav-item" key={index}>
                  <a className={activeStyle} href={`#${name}`}>
                    {name}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        {Object.entries(tabs).map(([name, component], index) => {
          if (name === activeTab) {
            return <span key={index}>{tabs[name]}</span>;
          }
        })}
      </div>
    </>
  );
}
