import React, { useEffect, useState } from 'react';
import api from '../../api';
import { showToast } from '../../components/Toast';
import Select from 'react-select';

export default function ManageDepartments() {
  const [processing, setProcessing] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const [options, setOptions] = useState([]);
  useEffect(() => {
    const getOptions = () => {
      api
        .get('/departments')
        .then((response) => {
          const data = response.data;
          setOptions(
            data.map((department) => {
              return {
                value: department.id,
                label: `${department.name} (${department.project_count})`,
              };
            })
          );
        })
        .catch((error) => {
          showToast('Something went wrong!');
        });
    };
    getOptions();
  }, []);
  const handleSelectChange = (e) => {
    setDeleteId(e.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData(e.target);
    api
      .post('/departments', formData)
      .then((response) => {
        setProcessing(false);
        showToast('Department Created');
        window.location.reload();
      })
      .catch((error) => {
        setProcessing(false);
        showToast('Something went wrong!');
      });
  };

  const handleDelete = () => {
    showToast('Deleting...');
    api
      .delete(`/departments/${deleteId}`)
      .then((response) => {
        showToast('Delete Success!');
        window.location.reload();
      })
      .catch((error) => {
        showToast('Something went wrong!');
      });
  };

  return (
    <>
      <div className="container-fluid mb-5">
        <h4>Create Department</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="name"
              required
            />
            <label htmlFor="floatingInput">Department Name</label>
          </div>

          <div className="form-floating">
            <textarea
              className="form-control"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="description"
              required
            />
            <label htmlFor="floatingInput">Description</label>
          </div>

          <button className="btn btn-lg btn-primary" type="submit">
            {processing ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>{' '}
                Creating Department...
              </>
            ) : (
              'Create Departement'
            )}
          </button>
        </form>
      </div>
      <div className="container-fluid mb-5">
        <h4 className="text-danger">Delete Department</h4>
        <p>
          <small className="text-muted">
            Please ensure that any department that will be deleted do not have
            any projects associated with them, deleting a department also
            deletes all associated projects.
          </small>
        </p>
        <Select
          options={options}
          isSearchable={true}
          placeholder="Search for Departments..."
          className="mb-3"
          onChange={handleSelectChange}
        />
        <button className="btn btn-lg btn-danger" onClick={handleDelete}>
          Delete
        </button>
      </div>
    </>
  );
}
