import React from 'react';
import { Link } from 'react-router-dom';
import global from '../../data/global.json';
import logo from '../../assets/iprojectedu_logo.png';
import './styles.css';
import { BiListUl } from 'react-icons/bi';
import TopBar from './TopBar';
import { FaUser, FaUserAlt } from 'react-icons/fa';

export default function NavBar() {
  return (
    <>
      <TopBar />
      <nav
        className="navbar navbar-expand-lg navbar-light sticky-top shadow-sm"
        id="mainNav"
      >
        <div className="container px-5">
          <Link className="navbar-brand fw-bold" to="/">
            <img src={logo} alt="logo" /> <span>{global.brand.name}</span>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <BiListUl />
            <span>Menu</span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
              <li className="nav-item">
                <Link to="/departments" className="nav-link me-lg-3">
                  Departments
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/projects" className="nav-link me-lg-3">
                  Projects
                </Link>
              </li>
            </ul>
            <div className="d-flex justify-content-between mb-3 mb-lg-0">
              <Link
                to="/hire-a-writer"
                className="btn btn-primary rounded px-3 me-3"
              >
                <span className="small">Hire A Writer</span>
              </Link>
              <Link
                to={
                  JSON.parse(localStorage.getItem('user'))
                    ? '/profile'
                    : '/sign-in'
                }
                className="btn btn-secondary"
              >
                <FaUserAlt />
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
