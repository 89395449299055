import React, { useEffect, useState } from 'react';
import Spinner from '../Spinner';
import { Link, useParams } from 'react-router-dom';
import { PiNotebookBold } from 'react-icons/pi';
import './styles.css';

export default function ProjectList({
  projects = [],
  countsPerPage = 50,
  paginated = true,
  fromASearch = false,
}) {
  projects = projects.sort((a, b) => a.title.localeCompare(b.title));
  const [pagedProjects, setPagedProjects] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [index, setIndex] = useState(1);
  const departmentId = useParams().id;

  useEffect(() => {
    setIndex(1);
  }, [departmentId]);

  useEffect(() => {
    setPagedProjects(
      projects.slice((index - 1) * countsPerPage, countsPerPage * index)
    );
    const makePagination = () => {
      const arr = [];
      for (let i = 1; i <= Math.ceil(projects.length / countsPerPage); i++) {
        arr.push(i);
      }
      setPagination(arr);
    };
    makePagination();
  }, [projects, index]);

  const handlePaginationClick = (e) => {
    setIndex(Number(e.target.id));
    window.scrollTo({
      top:
        document.getElementById('projectList').getBoundingClientRect().top +
        window.scrollY -
        150,
      behavior: 'smooth',
    });
  };

  if (fromASearch === true && projects.length === 0) {
    return (
      <>
        <div className="container-fluid">
          <h4 className="text-danger m-0">No Match Found!</h4>
          <p>
            <small className="text-muted">
              Sorry we cannot find what you are looking for at this time.
              Consider reaching out to us to hire a professional writer for your
              project topic.
            </small>
          </p>
        </div>
      </>
    );
  }

  if (projects.length === 0) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <div className="list-group list-group-flush" id="projectList">
          {pagedProjects.map((project, index) => {
            return (
              <Link
                className="list-group-item list-group-item-action d-flex justify-content-between"
                key={index}
                to={`/projects/${project.id}`}
              >
                <div className="d-flex">
                  <div className="me-2">
                    <PiNotebookBold
                      className="text-primary"
                      style={{ marginTop: '-3px' }}
                    />
                  </div>
                  <div className="project-title">{project.title}</div>
                </div>
              </Link>
            );
          })}
        </div>
        {paginated === true ? (
          <nav aria-label="Projects navigation">
            <ul className="pagination justify-content-center mt-3">
              {pagination.map((page) => {
                const activePage =
                  page === index ? 'page-item active' : 'page-item';
                return (
                  <li className={activePage} key={page}>
                    <button
                      className="page-link"
                      onClick={handlePaginationClick}
                      id={page}
                    >
                      {page}
                    </button>
                  </li>
                );
              })}
            </ul>
          </nav>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
