import React, { useEffect, useState } from 'react';
import api from '../../api';
import { showToast } from '../../components/Toast';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function ManageProjects() {
  const [processing, setProcessing] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [body, setBody] = useState('');
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'code-block',
    'align',
    'list',
    'script',
    'link',
    // 'image',
  ];

  const [projectOptions, setProjectOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  useEffect(() => {
    const getProjectOptions = () => {
      api
        .get('/projects')
        .then((response) => {
          const data = response.data;
          setProjectOptions(
            data.map((project) => {
              return {
                value: project.id,
                label: project.title,
              };
            })
          );
        })
        .catch((error) => {
          showToast('Something went wrong!');
        });
    };

    const getDepartmentOptions = () => {
      api
        .get('/departments')
        .then((response) => {
          const data = response.data;
          setDepartmentOptions(
            data.map((department) => {
              return {
                value: department.id,
                label: department.name,
              };
            })
          );
        })
        .catch((error) => {
          showToast('Something went wrong!');
        });
    };

    getProjectOptions();
    getDepartmentOptions();
  }, []);
  const handleSelectChange = (e) => {
    setDeleteId(e.value);
  };

  const handleChange = (value) => {
    setBody(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData(e.target);
    formData.set('body', body);
    api
      .post('/projects', formData)
      .then((response) => {
        setProcessing(false);
        showToast('Project Created');
        window.location.reload();
      })
      .catch((error) => {
        setProcessing(false);
        showToast('Something went wrong!');
        console.error(error);
      });
  };

  const handleDelete = () => {
    showToast('Deleting...');
    api
      .delete(`/projects/${deleteId}`)
      .then((response) => {
        showToast('Delete Success!');
        window.location.reload();
      })
      .catch((error) => {
        showToast('Something went wrong!');
      });
  };

  return (
    <>
      <div className="container-fluid mb-5">
        <h4>Create Project</h4>
        <form onSubmit={handleSubmit}>
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="title"
              required
            />
            <label htmlFor="floatingInput">Project Title</label>
          </div>

          <div className="form-floating">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="price"
              required
            />
            <label htmlFor="floatingInput">Project Price</label>
          </div>

          <div className="form-floating">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="chapters"
              required
            />
            <label htmlFor="floatingInput">Chapters</label>
          </div>

          <div className="form-floating">
            <input
              type="number"
              className="form-control"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="pages"
              required
            />
            <label htmlFor="floatingInput">Pages</label>
          </div>

          <ReactQuill
            aria-label="Project Body"
            value={body}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            style={{ height: '300px', marginBottom: '85px' }}
          />

          {/* <div className="form-floating">
            <textarea
              className="form-control project-body"
              id="floatingInput"
              placeholder="Field cannot be blank"
              name="body"
              required
            />
            <label htmlFor="floatingInput">Project Body</label>
          </div> */}

          <Select
            options={departmentOptions}
            isSearchable={true}
            placeholder="Select Department"
            className="mb-3"
            // onChange={handleSelectChange}
            name="department_id"
            required
          />

          <button className="btn btn-lg btn-primary" type="submit">
            {processing ? (
              <>
                <span
                  className="spinner-border spinner-border-sm me-2"
                  role="status"
                  aria-hidden="true"
                ></span>{' '}
                Creating Project...
              </>
            ) : (
              'Create Project'
            )}
          </button>
        </form>
      </div>
      <div className="container-fluid mb-5">
        <h4 className="text-danger">Delete Project</h4>
        <Select
          options={projectOptions}
          isSearchable={true}
          placeholder="Search for Projects..."
          className="mb-3"
          onChange={handleSelectChange}
        />
        <button className="btn btn-lg btn-danger" onClick={handleDelete}>
          Delete
        </button>
      </div>
    </>
  );
}
