import React from 'react';
import './styles.css';
import global from '../../../data/global.json';
import {
  BiEnvelope,
  BiPhone,
  BiLogoTwitter,
  BiLogoFacebook,
  BiLogoWhatsapp,
} from 'react-icons/bi';

export default function TopBar() {
  return (
    <>
      <div className="above-nav py-2">
        <div className="container d-flex justify-content-between">
          <div className="contact">
            {global.brand.emails.map((email, index) => {
              return (
                <div key={index}>
                  <a href={`mailto:${email}`}>
                    <BiEnvelope />
                    {email}
                  </a>
                </div>
              );
            })}
            {global.brand.phones.map((phone, index) => {
              return (
                <div key={index}>
                  <a href={`tel:${phone}`}>
                    <BiPhone />
                    {phone}
                  </a>
                </div>
              );
            })}
          </div>
          <div className="social">
            <a href={global.socials.twitter}>
              <BiLogoTwitter />
            </a>
            <a href={global.socials.facebook}>
              <BiLogoFacebook />
            </a>
            <a href={global.socials.whatsapp}>
              <BiLogoWhatsapp />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
