import React, { useState } from 'react';
import { setPageTitle } from '../../../utils/PageTitle';
import api from '../../../api';
import { showToast } from '../../../components/Toast';

export default function Reset() {
  setPageTitle('Reset Password');
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleSubmit = (e) => {
    e.preventDefault();
    setProcessing(true);

    const formData = new FormData(e.target);

    api
      .post('/reset', formData)
      .then((response) => {
        setProcessing(false);
        setSuccess(true);
      })
      .catch((error) => {
        setProcessing(false);
        if (error.response?.status === 404) {
          showToast('Invalid email!');
        } else {
          showToast('Something went wrong!');
        }
      });
  };

  if (success) {
    return (
      <>
        <h1>Reset token sent to your email!</h1>
        <p>
          You request was processed and a password reset link has been sent to
          your email address.
        </p>
        <p>Log in to your email to continue.</p>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <div className="container-fluid mb-5">
          <h1>Reset Password</h1>
          <small className="text-muted">
            Reseting your password requires that you have access to your
            registered email for verification. Supply all required information
            in the form below.
          </small>
          <form onSubmit={handleSubmit}>
            <div className="form-floating mt-3">
              <input
                type="email"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
                name="email"
                required
              />
              <label htmlFor="floatingInput">Email address</label>
            </div>
            <button className="w-100 btn btn-lg btn-primary" type="submit">
              {processing ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>{' '}
                  Processing...
                </>
              ) : (
                'Reset Password'
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
