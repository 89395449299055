import React, { useEffect } from 'react';
import api from '../../api';
import Spinner from '../../components/Spinner';

export default function Logout() {
  useEffect(() => {
    localStorage.removeItem('user');
    api
      .delete('/logout')
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    window.location = '/';
  }, []);

  return <Spinner />;
}
