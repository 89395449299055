import React from 'react';
import { Link } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { LuSchool2 } from 'react-icons/lu';
import './styles.css';

export default function DepartmentList({
  departments = [],
  fromASearch = false,
}) {
  departments = departments.sort((a, b) => a.name.localeCompare(b.name));

  if (fromASearch === true && departments.length === 0) {
    return (
      <>
        <div className="container-fluid">
          <h4 className="text-danger m-0">No Match Found!</h4>
          <p>
            <small className="text-muted">
              Sorry we cannot find what you are looking for at this time.
              Consider reaching out to us to hire a professional writer for your
              project topic.
            </small>
          </p>
        </div>
      </>
    );
  }

  if (departments.length === 0) {
    return <Spinner />;
  }

  return (
    <>
      <div>
        <div className="list-group list-group-flush">
          {departments.map((department, index) => {
            return (
              <Link
                className="list-group-item list-group-item-action d-flex justify-content-between align-items-center"
                key={index}
                to={`/departments/${department.id}`}
              >
                <div className="d-flex">
                  <div className="me-2">
                    <LuSchool2
                      className="text-primary"
                      style={{ marginTop: '-7px' }}
                    />
                  </div>
                  <div className="department-name">{department.name}</div>
                </div>
                <span className="badge bg-primary rounded-pill">
                  {department.project_count}
                </span>
              </Link>
            );
          })}
        </div>
      </div>
    </>
  );
}
