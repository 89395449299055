import React, { useState, useEffect } from 'react';
import ProjectList from '../../components/ProjectList';
import { setPageTitle } from '../../utils/PageTitle';
import api from '../../api';
import FAQs from '../../components/FAQs';
import banner from '../../assets/home_banner.jpg';

export default function Home() {
  setPageTitle();
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const getProjects = () => {
      api
        .get('/projects')
        .then((response) => {
          setProjects(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getProjects();
  }, []);
  return (
    <>
      <div className="container-fluid">
        <img src={banner} alt="iProjectEdu" className="img-fluid mb-3" />
        <h2>Instantly Get Your Complete Project Material:</h2>
        <small className="text-muted">
          <ul>
            <li>
              Choose an approved or related project topic from
              www.iprojectedu.com.
            </li>
            <li>
              Preview topic content and access samples for trust assurance.
            </li>
            <li>
              Contact our 24/7 Customer Service Desk via call, SMS, email, or
              WhatsApp.
            </li>
            <li>
              Enjoy immediate delivery of your meticulously researched project
              material.
            </li>
            <li>
              If you don't have an approved topic, select three free options
              from your department.
            </li>
            <li>
              Submit the topics to your supervisor and notify us upon approval.
            </li>
            <li>Receive your complete project material promptly.</li>
          </ul>
        </small>
        <p>
          We are committed to your success. We provide you with a hassle-free
          way to access comprehensive project materials that meet the highest
          standards. Don't wait any longer – made your choice today and let us
          empower you on your project journey.
        </p>
      </div>
      <div className="mt-3 mb-5">
        <ProjectList projects={projects} />
      </div>
      <div className="mb-5">
        <FAQs />
      </div>
    </>
  );
}
