import React, { useState, useEffect } from 'react';
import { setPageTitle } from '../../utils/PageTitle';
import DepartmentList from '../../components/DepartmentList';
import api from '../../api';

export default function Departments() {
  setPageTitle('Departments');
  const [departments, setDepartments] = useState([]);
  const [searchParam, setSearchParam] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const getDepartments = () => {
      api
        .get('/departments')
        .then((response) => {
          setDepartments(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    };
    getDepartments();
  }, []);

  useEffect(() => {
    setSearchResults(
      departments.filter((department) => {
        if (department.name.toLowerCase().includes(searchParam.toLowerCase())) {
          return true;
        }
        return false;
      })
    );
  }, [searchParam]);

  const handleSearch = (e) => {
    setSearchParam(e.target.value);
  };

  return (
    <>
      <div className="container-fluid mb-3">
        <h2>Department List</h2>
        <p>
          <small className="text-muted">
            Search from a diverse list of departments for your project needs.
          </small>
        </p>
        <input
          type="text"
          className="form-control"
          placeholder="Search Departments"
          value={searchParam}
          onChange={handleSearch}
        />
      </div>
      <div className="mb-5">
        <DepartmentList
          departments={searchParam === '' ? departments : searchResults}
          fromASearch={searchParam.length !== 0}
        />
      </div>
    </>
  );
}
